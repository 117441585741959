/**
 * This file should be avoided except as last resport for styling
 * third-party components.
 *
 * Almost all styling we require can be achieve through our Material UI
 * theme.
 *
 * Mark the purpose of any style blocks here clearly.
 */

/**
 * Restrict replaced media to container width
 * For some reason, this isn't included in CssBaseline, and applying
 * it via global override seems to have no effect.
 */
img,
video,
iframe,
embed {
  max-width: 100%;
}

* {
  padding: 0; 
  margin: 0;
}

/**
 * Don't allow children to push dimensions of flex/grid parents out
 */
* {
  min-width: 0;
  min-height: 0;
}

/**
 * Custom styling for react-trip-date
 * 1. The component supports colour customisation via Material theme,
 *    but not font, so we set it here.
 * 2. Corresponds to our default Material text size.
 * 3. Necessary to prevent scrolling when picker rendered in Material popover.
 */

.tp-calendar {
  font-family: "DM Sans", sans-serif; /* [1] */
  font-size: 0.875rem; /* [2] */
  padding-bottom: 1rem; /* [3] */
}
